export default {
  unknown_error: 'Возникла непредвиденная ошибка сервера. Пожалуйста, воспользуйтесь формой обратной связи.',
  captcha_error: 'Подтвердите, что Вы не робот!',
  continue: 'Продолжить',
  submit: 'Отправить',
  learn_more: 'Подробно',
  clear: 'Очистить',
  search: 'Поиск',
  search_by_number: 'Поиск по списку',
  product_code: 'Артикул',
  product_name: 'Наименование',
  no_vat: 'без НДС',
  price: 'Цена',
  price_rub: 'Цена, руб.',
  sum_rub: 'Сумма, руб.',
  total_vat_rub: 'Итого с НДС, руб.',
  total_rub: 'Итого, руб.',
  vat_rub: 'НДС (20%), руб.',
  quantity: 'Количество',
  profile: 'Профиль',
  login: 'Логин',
  first_name: 'Имя',
  last_name: 'Фамилия',
  company_name: 'Название компании',
  company_ownership: 'Форма собственности',
  country_phone: 'Страна и номер телефона',
  inn: 'ИНН',
  kpp: 'КПП',
  set_password: 'Задайте пароль',
  confirm_password: 'Повторите пароль',
  news: 'Новости',
  all_news: 'Все новости',
  orders: 'Заказы',
  favourites: 'Избранное',
  to_homepage: 'На главную',
  cart: 'Корзина',
  go_to_cart: 'Перейти в корзину',
  express: 'Экспресс',
  prev_btn: 'Назад',
  next_btn: 'Далее',
  price_requests: 'Мои запросы',
  search_history: 'История загрузок списком',
  print: 'Печать',
  save: 'Сохранить',
  specialization: 'Специализация',
  all_vehicles: 'Все типы транспортных средств',
  where_to_buy: 'Где купить?',
  list_of_distributors: 'Список дистрибьюторов',
  download_order_file: 'Скачать файл заказа',
  select_distributor: 'Выбрать дистрибьютора',
}
